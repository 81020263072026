<template>
  <a-row :gutter="6" style="padding: 10px">
    <a-col :md="6">
      <div class="left">
        <div class="left-top">
          <h2>欢迎语</h2>
          <p style="font-size: 18px">
            {{ welcome }}
          </p>
        </div>
        <div class="content">
          <div class="list title">
            基本信息
          </div>
          <div class="list" v-for="(d, k) of info" :key="k">
            <p class="label">{{ d.label }}</p>
            <p class="name">{{ d.name }}</p>
          </div>
        </div>
      </div>
    </a-col>
    <a-col :md="18">
      <div class="right">
        <div class="right-top content">
          <div class="list title center">甄别部分</div>
          <div class="list" v-for="(d, k) of distinguish" :key="k">
            <p class="label">{{ d.label }}</p>
            <a-radio-group class="name" v-model="questionForm[d.label.split('、')[0]].list">
              <a-radio v-for="(item, index) of d.name" :key="'item_distinguish_' + index" :value="item.value">
                {{ replaceInfo(item.text) }}
              </a-radio>
            </a-radio-group>
          </div>
          <div class="list title center">
            <span class="label" style="margin-right: 30px">数据是否有效?</span>
            <span>
              <a-radio-group class="name" v-model="dataIsEffective">
                <a-radio :value="true">
                  有效
                </a-radio>
                <a-radio :value="false">
                  无效
                </a-radio>
              </a-radio-group>
            </span>
            <a-button type="primary" @click="dataSubmit" v-if="title !== '查看'">提交</a-button>
            <a-button @click="back">返回</a-button>
          </div>
        </div>
        <div v-show="sampShow" class="content" style="margin-top: 10px">
          <div class="list title center">
            问卷部分
            <div class="content-tips">{{ guide }}</div>
          </div>
          <div class="list" v-for="(d, k) of questionnaire" :key="k">
            <p class="label">{{ d.label }}</p>
            <a-radio-group class="name" v-if="d.type === 'radio'" v-model="questionForm[d.label.split('、')[0]].list">
              <a-radio v-for="(item, index) of d.name" :key="'item_questionnaire_radio_' + index" :value="item.value">
                {{ replaceInfo(item.text) }}
              </a-radio>
              <a-textarea :rows="4" v-if="d.textarea" v-model="questionForm[d.label.split('、')[0]].remark" />
            </a-radio-group>
            <a-checkbox-group class="name" v-if="d.type === 'checkbox'" v-model="questionForm[d.label.split('、')[0]].list">
              <a-row>
                <a-col :span="6" v-for="(item, index) of d.name" :key="'item_questionnaire_checkbox_' + index">
                  <a-checkbox :value="item.value">
                    {{ replaceInfo(item.text) }}
                  </a-checkbox>
                </a-col>
                <a-col>
                  <a-textarea :rows="4" v-model="questionForm[d.label.split('、')[0]].remark" />
                </a-col>
              </a-row>
            </a-checkbox-group>
          </div>
          <div class="list title center">
            <span class="label" style="margin-right: 30px">样本是否有效?</span>
            <span>
              <a-radio-group class="name" v-model="sampleIsEffective">
                <a-radio :value="true">
                  有效
                </a-radio>
                <a-radio :value="false">
                  无效
                </a-radio>
              </a-radio-group>
            </span>
            <a-button type="primary" @click="sampSubmit" v-if="title !== '查看'">提交</a-button>
            <a-button @click="back">返回</a-button>
          </div>
        </div>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import { questionDetail, updateQuestion } from '@/api/questionnaire/record'
import * as QUESTION from '@/views/questionnaire/questions.js'
import cloneDeep from 'lodash.clonedeep'

export default {
  name: 'EditMarket',
  props: {
    editData: {
      type: Object,
      default: function() {
        return {}
      }
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      info: [
        { label: '乡镇（街道）', name: '' },
        { label: '村（社区）', name: '' },
        { label: '联系电话', name: '' }
      ],
      // 欢迎语
      welcome: QUESTION.welcome,
      // 引导语(问卷)
      guide: QUESTION.guide,
      // 甄别题
      distinguish: cloneDeep(QUESTION.question.distinguish),
      // 问卷题
      questionnaire: cloneDeep(QUESTION.question.questionnaire),
      // 问卷表单项
      questionForm: cloneDeep(QUESTION.form),
      dataIsEffective: null,
      sampleIsEffective: null,
      sampShow: false
    }
  },
  mounted() {
    this.detail()
  },
  methods: {
    replaceInfo(text) {
      return text.replace(/{(\d+)}/g, (_match, param) => {
        return this.info[Number(param)].name
      })
    },
    detail() {
      const { id, questionId } = this.editData
      questionDetail({ id, questionId }).then(res => {
        console.log(res)
        if (res.status === 200) {
          this.info[0].name = res.body.townName
          this.info[1].name = res.body.villageName
          this.info[2].name = res.body.phone
          if (res.body.questionData) {
            for (const i in res.body.questionData) {
              this.questionForm[i] = JSON.parse(res.body.questionData[i])
            }
          }
          this.dataIsEffective = res.body.dataIsEffective
          this.sampleIsEffective = res.body.sampleIsEffective
          if (this.dataIsEffective) {
            this.sampShow = true
          } else {
            this.sampShow = false
          }
        }
      })
    },
    dataSubmit() {
      if (this.dataIsEffective === null || this.dataIsEffective === undefined) {
        this.$notification.error({
          message: '提示',
          description: '请选择是否有效'
        })
        return
      }
      if (this.dataIsEffective === false) {
        this.sampleIsEffective = false
        this.submit()
      } else {
        this.sampShow = true
      }
    },
    sampSubmit() {
      if (this.sampleIsEffective === null || this.sampleIsEffective === undefined) {
        this.$notification.error({
          message: '提示',
          description: '请选择是否有效'
        })
        return
      }
      this.submit()
    },
    submit() {
      const { dataIsEffective, questionForm, sampleIsEffective, editData } = this
      const form = { ...questionForm }
      for (const i in form) {
        form[i] = JSON.stringify(form[i])
      }
      updateQuestion({ dataIsEffective, sampleIsEffective, questionData: form, id: editData.id })
        .then(res => {
          if (res.status !== 200) {
            this.$notification.error({
              message: '提示',
              description: res.message
            })
            return
          }
          this.$notification.success({
            message: '提示',
            description: res.message
          })
          this.back()
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
        .finally(() => {
          this.confirmLoading = false
        })
    },
    back() {
      this.$emit('cut', 'ListMarket')
    }
  }
}
</script>

<style scoped>
.left {
  height: calc(100vh - 220px);
  overflow-y: auto;
  background: white;
}
.right {
  height: calc(100vh - 220px);
  overflow-y: auto;
}
.left-top {
  background: #f8fbfc;
  box-sizing: border-box;
  padding: 20px;
}
.content {
  background: #ffffff;
}
.list {
  border-top: 1px solid #eeeeee;
  padding: 20px;
}
.list > p {
  margin-bottom: 0.5em;
}
.title {
  font-size: 20px;
  color: #1890ff;
}
.label {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
}
.name {
  font-size: 20px;
  font-weight: 400;
  color: #666666;
}
.center {
  text-align: center;
}
.content-tips {
  color: gray;
  font-size: 15px;
  padding-top: 10px;
}
</style>
